import { useUser } from "../auth/UserContext";
import { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useGetUserProtocolsV1Query } from "../store/penpalServerAPI";

const Cage = lazy(() => import("../pages/Cage"));
const Staff = lazy(() => import("../pages/Staff"));
const BugForm = lazy(() => import("../pages/BugForm"));
const ClinCase = lazy(() => import("../pages/ClinCase"));
const Settings = lazy(() => import("../pages/Settings"));
const Reports = lazy(() => import("../pages/PI/Reports"));
const Devices = lazy(() => import("../pages/PI/Devices"));
const Dashboard = lazy(() => import("../pages/PI/Dashboard"));
const Analytics = lazy(() => import("../pages/PI/Analytics"));
const Protocols = lazy(() => import('../pages/PI/Protocols'));
const NewSession = lazy(() => import("../pages/PI/NewSession"));
const Notifications = lazy(() => import("../pages/Notifications"));
const ClinicalCases = lazy(() => import("../pages/ClinicalCases"));
const NewNote = lazy(() => import("../components/forms/NewNoteForm"));
const UpdateSession = lazy(() => import("../pages/PI/UpdateSession"));
const CageNotes = lazy(() => import("../components/sections/CageNotes"));
const PageNotFound = lazy(() => import("../components/global/PageNotFound"));
const NewClinCase = lazy(() => import("../components/forms/NewClinCaseForm"));
const NewObservation = lazy(() => import("../components/forms/NewObservationForm"));
const LoadingScreen = lazy(() => import("../components/global/LoadingScreen"));

const PI = () => {
  const user = useUser();

  const {
    data: protocolsData,
    isLoading: protocolsDataIsLoading
  } = useGetUserProtocolsV1Query(user.UserID);

  useEffect(() => {
    if (localStorage.getItem('penpal path') === null) return;
    if (localStorage.getItem('penpal path') !== null && window.document.location.pathname === localStorage.getItem('penpal path')) {
      localStorage.removeItem('penpal path');
    }
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen screenHeight={true} />}>
        <Routes>
          <Route path="/" lazy={<LoadingScreen screenHeight={true} />} element={<Protocols protocolsData={protocolsData} protocolsDataIsLoading={protocolsDataIsLoading} />} />
          <Route path="/penpals/:id" element={<Cage />} />
          <Route path="/penpals/:id/notes/:sessionId" element={<CageNotes />} />
          <Route path="/penpals/:id/notes/:sessionId/new" element={<NewNote />} />
          <Route path="/clinical-cases/new/:sessionId/:naturalId" element={<NewClinCase />} />
          <Route path="/session/update/:id/:mac" element={<UpdateSession />} />
          <Route path="/penpals" element={<Devices />} />
          <Route path="/activate/:id" element={<NewSession />} />
          <Route path="/pi-dashboard" element={<Dashboard protocolsData={protocolsData} protocolsDataIsLoading={protocolsDataIsLoading} />} />
          <Route path="/clinical-cases" element={<ClinicalCases />} />
          <Route path="/clinical-cases/:id" element={<ClinCase />} />
          <Route path="/clinical-cases/:id/new-observation" element={<NewObservation />} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/notifications/:id" element={<Notifications />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          <Route path="/support" />
          <Route path="/reports" element={<Reports />} />
          <Route path="/support/reportabug" element={<BugForm />} />
          <Route path="/penpals/:id/analytics" element={<Analytics />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default PI;
