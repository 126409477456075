import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from "@auth0/auth0-react";
import CheckAuthorized from "./CheckAuthorized";
import { setAuthToken } from "../store/authSlice";

const GetAuth0Token = () => {
  const dispatch = useDispatch();
  const auth0Token = useSelector((state) => state.auth?.token)
  const [token, setToken] = useState('');
  const {
    user: userAuth0,
    isLoading: isLoadingAuth0,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(setAuthToken(accessToken));
      } catch (error) {
        if (error.error === 'login_required') {
          console.log('Token expired, forcing login');
          logout({ returnTo: window.location.origin });
        }
        console.error('Failed to get access token:', error);
        logout({ returnTo: window.location.origin });
      }
    };

    getToken()

    const intervalId = setInterval(getToken, 15 * 60 * 1000);

    return () => clearInterval(intervalId)
  }, []);

  if (auth0Token)
    return (
      <CheckAuthorized email={userAuth0.email} />
    );
};

export default GetAuth0Token;
