import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../../auth/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocalStorage } from "../context/LocalStorageContext";

const UserMenu = ({ tools = [] }) => {
  const user = useUser();
  const [open, setOpen] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const toolbar = useRef(null);
  const background = useRef(null);
  const navigate = useNavigate();
  const { logout, user: auth0User } = useAuth0();
  const { tempPref, setTempPref, setRolePref } = useLocalStorage();

  useEffect(() => {
    let root = document.getElementById("root");
    if (background.current == null || toolbar.current == null) return;
    else {
      if (!openTools) {
        root.classList.remove("overflow-hidden");
        setOpen(false);
        setTimeout(() => {
          background.current?.classList?.add("hidden");
          toolbar.current?.classList?.add("hidden");
        }, 400);
      } else {
        setTimeout(() => {
          root.classList.add("overflow-hidden");
        }, 400);
        background.current?.classList?.remove("hidden");
        toolbar.current?.classList?.remove("hidden");
        setOpen(true);
      }
    }
  }, [openTools]);

  const selectRoleGroup = (role) => {
    setOpen(false);
    setOpenTools(false);
    setRolePref(role);
    navigate("/");
  };

  const handleLogout = async () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <div className="flex flex-col items-end overflow-hidden">
      <div className="flex items-center transition-all duration-300 ease-in-out">
        <div
          className="flex items-center transition-all duration-300 ease-in-out rounded"
        // onClick={() => setOpenTools(!openTools)}

        >
          {user.Authorized ? (
            <div className="flex flex-col font-magBook ml-2 tracking-wider text-black">
              <p className={`${open ? "z-[350] text-white" : "text-black"} text-lg font-bold transition-all duration-300 ease-in-out underline`}>
                {user.FirstName} {user.LastName}
              </p>
              <p
                className={`${open ? "text-white z-[350]" : "text-black"
                  } text-sm font-light transition-all duration-300 ease-in-out`}
              >
                {user.SelectedRole === 'NONE' ? null : user.SelectedRole}
              </p>
            </div>
          ) : (
            <p>{auth0User.email}</p>
          )}
        </div>
      </div>
      <div
        ref={toolbar}
        className={`${open ? "w-[222px] opacity-100 z-[350]" : "w-0 opacity-0"
          } absolute overflow-hidden ${user.Authorized ? "top-14" : "top-10"} right-4 flex flex-col items-start rounded-lg dark:bg-slate-800 dark:text-white bg-stone-50 transition-all duration-300 ease-in-out`}
      >
        <p
          className={`${open ? "w-auto opacity-100" : "w-0 z-[0] opacity-0"
            } text-2xl font-magBold px-3 py-1 transition-all ease-in-out duration-300`}
        >
          Menu
        </p>
        <div className="flex flex-col w-full h-full items-center justify-between">
          {tools &&
            tools.map((tool) => {
              return (
                <Link
                  to={tool.href}
                  key={tool.href}
                  className={`${open ? "opacity-100" : "opacity-0"
                    } p-2 whitespace-nowrap font-chaletSixty text-2xl text-center w-9/12 bg-white shadow-md rounded-md my-2 transition-all duration-300 ease-in-out`}
                >
                  {tool.title}
                </Link>
              );
            })}
          <div className={`w-full flex flex-col items-center`}>
            {user.Authorized && user.Roles.length ?
              (<div className={`w-full flex flex-col items-center my-1 border-t`}>
                <p className="w-full pl-3 text-lg font-semibold">Change Role</p>
                <div className="flex flex-wrap mt-1">
                  {user.Roles.filter((role) => {
                    return role !== user.SelectedRole
                  }).map((role) => {
                    return (<button
                      key={role}
                      className="mx-2 px-3 py-1 my-1 rounded-md shadow-md dark:bg-slate-700"
                      onClick={() => selectRoleGroup(role)}
                    >
                      {role}
                    </button>)
                  })}
                </div>
              </div>) : null}
            <div className={`w-full flex flex-col items-center border-t`}>
              {user.Authorized ? (
                <>
                  <p className="w-full pl-3 text-lg font-semibold">My Settings</p>
                  <div className={`w-10/12 flex justify-between items-center`}>
                    <div className={`my-2`}>
                      <p className={``}>Temp</p>
                    </div>
                    <div className={`flex`}>
                      <button onClick={() => setTempPref('F')} className={`${tempPref === 'F' ? "bg-green-600" : "bg-sunburst"} mx-2 w-[30px] h-[28px] py-1 rounded flex justify-center items-center`}>
                        <span>&#176;</span>F
                      </button>
                      <button onClick={() => setTempPref('C')} className={`${tempPref === 'C' ? "bg-green-600" : "bg-sunburst"} mx-2 w-[30px] h-[28px] py-1 rounded flex justify-center items-center`}>
                        <span>&#176;</span>C
                      </button>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="flex justify-around my-3 px-4 w-full mt-6">
              {/* <button className="basis-1/2 py-1 mx-1 rounded-md shadow-md bg-stone-400 text-white">
                Settings
              </button> */}
              <button
                className="basis-1/2 mx-1 py-1 rounded-md shadow-md bg-red-500 text-white"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={background}
        onClick={() => setOpenTools(false)}
        className={`${open ? "opacity-60 z-[300]" : "opacity-0 -z-[500]"
          } h-full fixed inset-0 bg-black transition-all duration-300 ease-in-out`}
      ></div>
    </div>
  );
};

export default UserMenu;
