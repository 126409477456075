import "./App.css";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Signup from "./pages/Signup";
import LoadingScreen from "./components/global/LoadingScreen";
import LoginRedirect from "./pages/LoginRedirect";
import { useAuth0 } from "@auth0/auth0-react";
import GetAuth0Token from "./auth/GetAuth0Token";
import { useEffect } from "react";

function App() {
  if (window.location.origin === 'https://penpal.azurewebsites.net') {
    window.location.href = 'https://penpal.day'
  }
  const { isAuthenticated, isLoading } = useAuth0();
  
  useEffect(() => {
    // Change the color above the safe area to slate-900 for mobile devices.
    document.querySelector("meta[name='theme-color']").setAttribute("content", '#0f172a');

    if (window.document.location.pathname.length > 1) {
      localStorage.setItem('penpal path', window.document.location.pathname)
    };
  }, []);

  if (isLoading)
    return (
      <div className="w-screen h-screen dark:bg-slate-900">
        <LoadingScreen />
      </div>
    );

  if (isAuthenticated) return <GetAuth0Token />;

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center dark:bg-slate-900">
      <BrowserRouter>
        <Routes>
          <Route path="/" index element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/*" element={<LoginRedirect />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
