import { createContext, useContext, useState, useEffect } from "react";
import crypto from 'crypto-js';
import { useLazyGetLocalStorageKeyV1Query } from "../../store/penpalServerAPI";
import { useUser } from "../../auth/UserContext";

const initialLocalStorage = {
  protocolPref: null,
  setProtocolPref: () => { },
  rolePref: null,
  setRolePref: () => { },
  tempPref: null,
  setTempPref: () => { }
}

export const LocalStorageContext = createContext(initialLocalStorage);

export const useLocalStorage = () => {
  return useContext(LocalStorageContext);
};

export const LocalStorageProvider = ({ children }) => {
  let ls = localStorage;
  const user = useUser();
  const [protocolPref, setProtocolPref] = useState('');
  const [rolePref, setRolePref] = useState('');
  const [tempPref, setTempPref] = useState('');

  const [getKey, { data, error, isLoading, isSuccess, isError, isFetching }] = useLazyGetLocalStorageKeyV1Query();

  const encryptValue = (string, key) => {
    return crypto.AES.encrypt(string, key).toString();
  };

  const decryptValue = (string, key) => {
    return crypto.AES.decrypt(string, key).toString(crypto.enc.Utf8);
  };

  useEffect(() => {
    if (isError) {
      console.log('Error: ', error)
      if (error.status === 204) {
        console.log('Error: Couldn\'t retreive secret key')
      }
    }
  }, [isError, error])

  useEffect(() => {
    if (isSuccess && data) {
      if (ls.getItem('penpalApp') === null) {
        ls.setItem('penpalApp', '');
      } else {
        let decryptedValue = decryptValue(ls.getItem('penpalApp'), data);
        if (!decryptedValue) {
          ls.setItem('penpalApp', '');
        } else {
          let { protocolPref, tempPref, rolePref } = JSON.parse(decryptedValue);
          if (protocolPref) setProtocolPref(protocolPref);
          if (rolePref) setRolePref(rolePref);
          if (tempPref) setTempPref(tempPref);
        }
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (user?.Authorized) getKey();
  }, [user])

  useEffect(() => {
    if (isSuccess && data) {
      let value = encryptValue(JSON.stringify({ protocolPref, rolePref, tempPref }), data);
      ls.setItem('penpalApp', value);
    }
  }, [protocolPref, rolePref, tempPref, isSuccess, data]);

  return (
    <LocalStorageContext.Provider
      value={{
        protocolPref,
        setProtocolPref,
        rolePref,
        setRolePref,
        tempPref,
        setTempPref
      }}>
      {children}
    </LocalStorageContext.Provider>
  )
};
