import { createContext, useContext, useState } from "react";

const getTheme = () => {
  // if (localStorage.getItem("penPalDarkMode") === null)
  //   localStorage.setItem("penPalDarkMode", "false");
  // return localStorage.getItem("penPalDarkMode") === "true";
};

const changeTheme = () => {
  // if (localStorage.getItem("penPalDarkMode") === "true") {
  //   localStorage.setItem("penPalDarkMode", "false");
  // } else {
  //   localStorage.setItem("penPalDarkMode", "true");
  // }
};

export const DarkModeContext = createContext(getTheme());
export const ToggleDarkModeContext = createContext();

export const useDarkMode = () => {
  return useContext(DarkModeContext);
};
export const useToggleDarkMode = () => {
  return useContext(ToggleDarkModeContext);
};

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(getTheme());

  const toggleDarkMode = () => {
    setDarkMode((mode) => !mode);
    changeTheme();
  }

  return (
    <DarkModeContext.Provider value={darkMode}>
      <ToggleDarkModeContext.Provider value={toggleDarkMode}>
        {children}
      </ToggleDarkModeContext.Provider>
    </DarkModeContext.Provider>
  );
};
