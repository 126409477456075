import { BrowserRouter } from "react-router-dom"
import CommonHeader from "./CommonHeader"

const InternalServerError = () => {

  function reloadWindow() {
    window.document.location.reload()
  }

  return (
    <BrowserRouter>
      <div className="w-full h-screen flex flex-col items-center">
        <CommonHeader />
        <div className="flex flex-col justify-center items-center w-full h-[80vh]">
          <div className="flex justify-between items-center w-[240px]">
            <p className="text-3xl font-bold dark:text-white">500</p>
            <p className="text-2xl dark:text-white">|</p>
            <p className="font-bold dark:text-white">Internal Server Error</p>
          </div>
          <div className="mt-10">
            <button
              className="px-3 py-2 bg-sunburst text-white rounded"
              onClick={reloadWindow}
            >
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </BrowserRouter>
  )
};

export default InternalServerError;