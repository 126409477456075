import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import bell from "../../icons/notification.png";
import useNotifications from "../../hooks/useNotifications";
import { useLocalStorage } from "../context/LocalStorageContext";

const NotificationsMenu = () => {
  const navigate = useNavigate();
  const { protocolPref } = useLocalStorage();

  const {
    notifs,
    refetchNotifsInfo,
    refetchNotifTypesInfo,
  } = useNotifications();

  useEffect(() => {
    let timeoutId;

    const fetchNotifications = () => {
      refetchNotifsInfo();
      refetchNotifTypesInfo();
      timeoutId = setTimeout(fetchNotifications, 10000);
    };

    fetchNotifications();

    return () => clearTimeout(timeoutId);
  }, []);

  const filteredNotifs = notifs.filter((c) => c.protocolId === protocolPref);

  return (
    <>
      <button
        className={`h-[45px] flex items-center mr-2 `}
        onClick={() => {navigate("/notifications")}}
      >
        <img src={bell} alt="Notifications" className="relative mr-2 h-[30px] w-[30px]" />
        {filteredNotifs.length > 0 && (
          <div className="absolute right-[72px] top-[18px] h-[18px] min-w-[20px] bg-red-600 text-white rounded-full text-xs flex justify-center items-center">
            {filteredNotifs.length}
          </div>
        )}
      </button>
    </>
  );
};

export default NotificationsMenu;
