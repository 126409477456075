import { useUser } from "../auth/UserContext";
import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Cage = lazy(() => import("../pages/Cage"));
const Staff = lazy(() => import("../pages/Staff"));
const BugForm = lazy(() => import("../pages/BugForm"));
const Settings = lazy(() => import("../pages/Settings"));
const ClinCase = lazy(() => import("../pages/ClinCase"));
const Devices = lazy(() => import("../pages/Vet/Devices"));
const AdminHome = lazy(() => import("../pages/Admin/AdminHome"));
const ClinicalCases = lazy(() => import("../pages/ClinicalCases"));
const PageNotFound = lazy(() => import("../components/global/PageNotFound"));
const LoadingScreen = lazy(() => import("../components/global/LoadingScreen"));

// import { useGetAuthenticatedUserDetailsQuery, useGetUsersByInstitutionQuery } from "../store/penpalApiSlice";

const Admin = () => {
  const user = useUser();
  // const { data } = useGetAuthenticatedUserDetailsQuery(user.Email)
  // const { data: staffData, isLoading: staffIsLoading, error: staffError } = useGetUsersByInstitutionQuery(user.InstitutionID)

  useEffect(() => {
    if (localStorage.getItem('penpal path') === null) return;
    if (localStorage.getItem('penpal path') !== null && window.document.location.pathname === localStorage.getItem('penpal path')) {
      localStorage.removeItem('penpal path');
    }
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/" element={<AdminHome />} />
          <Route path="/*" element={<PageNotFound/>} />
          <Route path="/staff" element={<Staff />} />
          <Route path="/penpals" element={<Devices />}/>
          <Route path="/penpals/:id" element={<Cage />} />
          <Route path="/clinical-cases/:id" element={<ClinCase />} />
          {/* <Route path="/settings" element={<Settings />} /> */}
          <Route path="/clinical-cases" element={<ClinicalCases />} />
          <Route path="/support/reportabug" element={<BugForm />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Admin;
