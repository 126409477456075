import { Suspense, useEffect } from "react";
import Admin from "../roles/Admin";
import CareStaff from "../roles/CareStaff";
import LabAssociate from "../roles/LabAssociate";
import PI from "../roles/PI";
import Vet from "../roles/Vet";
import { useUser } from "./UserContext";
import CommonHeader from "../components/global/CommonHeader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingScreen from "../components/global/LoadingScreen";
import PageNotFound from "../components/global/PageNotFound";

const CheckRole = () => {
  const user = useUser();

  if (user.SelectedRole === "PI") return <PI />;
  else if (user.SelectedRole === "VET") return <Vet />;
  else if (user.SelectedRole === "PI STAFF") return <LabAssociate />;
  else if (user.SelectedRole === "CARE STAFF") return <CareStaff />;
  else if (user.SelectedRole === "ADMIN") return <Admin />;
  else if (user.SelectedRole === 'NONE') return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route path="/" lazy={<LoadingScreen />} element={
            <div className="h-[90dvh] w-screen">
              <CommonHeader />
              <div className="flex flex-col w-full h-full justify-center items-center dark:text-white">
                  <p className="text-2xl font-bold my-8">You currently have no roles.</p>
                  <p className="">If you think this is an error, reach out to your administrator.</p>
              </div>
            </div>
          } />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default CheckRole;
