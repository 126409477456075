import { useEffect, useState } from "react";
import { useSetUser } from "../../auth/UserContext";
import { useNavigate } from "react-router-dom";
import CreateAccount from "../CreateAccount";
import { useGetUserAuthRequestByEmailV1Query } from "../../store/penpalServerAPI";
import { useAuth0 } from "@auth0/auth0-react";
import UserData from "./UserData";
import LoadingScreen from "../../components/global/LoadingScreen";
import CommonHeader from "../../components/global/CommonHeader";

const UnauthorizedHome = () => {
  const { user } = useAuth0();
  const updateUser = useSetUser();
  const navigate = useNavigate();
  const [status, setStatus] = useState('');
  const [roles, setRoles] = useState([]);
  const [statusBg, setStatusBg] = useState('');

  const {
    user: userAuth0,
    isAuthenticated,
    isLoading: isLoadingAuth0,
    auth0Client,
    getAccessTokenSilently,
  } = useAuth0();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUserAuthRequestByEmailV1Query(userAuth0.email);

  useEffect(() => {
    if (isError) {
      setStatus('Awaiting Submission');
      setStatusBg('bg-red-400');
    }
    if (isLoading) {
      setStatus('Loading...');
      setStatusBg('bg-stone-50');
    }
    if (isSuccess && user.email) {
      setStatus('Submitted');
      setStatusBg('bg-green-500');
      let institution = '';
      let emailEnd = user.email.split('@')[1];
      let institutionName = emailEnd.split('.')[0];
      if (institutionName == 'tricorderarray') {
        institution = 'TRICORDER'
      } else if (institutionName == 'uab') {
        institution = 'UAB'
      };
      updateUser({
        Authorized: false,
        DateTimeCreated: data.createdAt,
        Email: data.email,
        FirstName: data.firstName,
        LastName: data.lastName,
        ID: data.userId,
        InstitutionID: institution,
        Phone: data.phone,
        Fax: data.fax,
      })
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
  }, [status])

  return (
    <div
      className={`min-h-[74vh] w-screen z-20 flex flex-col items-center`}
    >
      <div className="w-full min-h-[100vh] flex flex-col justify-between">
        <div className="w-full flex flex-col items-center">
          <CommonHeader title="" />
          <div className={`flex justify-around mt-4 py-3 text-md ${statusBg} ${status.includes('Submi') ? 'text-white' : ''} font-chaletNineteenSixty border rounded-lg`}>
            <p className="mx-2">Status:</p>
            <p className={`mr-2`}>{status}</p>
          </div>
          {isSuccess ? <UserData authReqData={data} /> : <CreateAccount isLoading={isLoading} />}
        </div>
        <div className="flex flex-col mt-4 mb-2 justify-center items-center w-screen text-sm font-chaletSixty dark:text-white">
          <p>&copy;Copyright 2023 Tricorder Array Technologies, LLC</p>
          <p>All Rights Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedHome;
