import { useUser } from "../../auth/UserContext"
import { useAuth0 } from "@auth0/auth0-react";
import { usePutUserAuthRequestV1Mutation } from "../../store/penpalServerAPI";
import { useEffect, useState } from "react";
import EditUserData from "./EditUserData";

const UserData = ({ authReqData }) => {
  const user = useUser();
  const [edit, setEdit] = useState(false);

  useEffect(() => {

  }, [edit, authReqData])

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-11/12 max-w-[500px] py-6 shadow-lg rounded-xl px-1 flex flex-col justify-center items-center my-4 font-chaletNineteenSixty bg-stone-50">
        <div className="flex w-11/12 mb-4">
          <p className="text-lg font-bold">
            Welcome back
          </p>
          {authReqData.firstName ? (
            <p className="text-lg">
              &nbsp;{authReqData.firstName}.
            </p>
          ) : (
            <p className="text-lg">.</p>
          )}
        </div>
        <p className="w-11/12 text-justify">
          You've successfully submitted a request for authorization. Your
          administrator hasn't authorized you just yet. If any information below is inaccurate, please update and reach out to your administrator.
        </p>
      </div>
      {edit ? (
        <div className="w-full flex justify-center">
          <EditUserData authReqData={authReqData} setEdit={setEdit} />
        </div>
      )
        : (
          <div className="flex flex-col w-10/12 max-w-[500px] p-4 rounded-xl bg-stone-50 shadow-lg">
            <div className="flex justify-between border-b">
              <p className="">Email</p>
              <p className="">{authReqData.email}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">First Name</p>
              <p className="">{authReqData.firstName}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Last Name</p>
              <p className="">{authReqData.lastName}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Phone</p>
              <p className="">{authReqData.phone}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Fax</p>
              <p className="">{authReqData.fax}</p>
            </div>
            <div className="flex justify-between border-b">
              <p className="">Institution</p>
              <p className="">{user.InstitutionID}</p>
            </div>
            <div className="w-full flex justify-center mt-4">
              <button className="rounded py-1 px-4 bg-sunburst text-white" onClick={() => setEdit(true)}>Edit</button>
            </div>
          </div>
        )}
    </div>
  );
};

export default UserData;