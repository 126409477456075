import { useEffect, useState } from "react"
import { useUser } from "../../auth/UserContext";
import { usePutUserAuthRequestV1Mutation } from "../../store/penpalServerAPI";

const EditUserData = ({ authReqData, setEdit }) => {
  const user = useUser();
  const [email, setEmail] = useState(authReqData.email);
  const [firstName, setFirstName] = useState(authReqData.firstName);
  const [lastName, setLastName] = useState(authReqData.lastName);
  const [phone, setPhone] = useState(authReqData.phone);
  const [fax, setFax] = useState(authReqData.fax);
  const [errors, setErrors] = useState([]);

  const [updateAuthRequest, { data, isLoading, isError, isSuccess, error }] = usePutUserAuthRequestV1Mutation();


  function checkErrors(obj) {
    if (!obj.Email) {
      setErrors([...errors, "Email Required"])
      return false;
    }
    if (!obj.FirstName) {
      setErrors([...errors, "First Name Required"])
      return false;
    }
    if (!obj.LastName) {
      setErrors([...errors, "Last Name Required"])
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    let newUserData = {
      UserID: authReqData.userId,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Fax: fax
    }
    if (checkErrors(newUserData)) {
      await updateAuthRequest(newUserData)
    } else {
      console.log("Required fields missing.")
      console.log(error)
    }
  }

  useEffect(() => {
    setErrors([]);
  }, [email, firstName, lastName])

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [data, isSuccess, isError])

  return (
    <div className="flex flex-col w-10/12 max-w-[500px] p-4 rounded-xl bg-stone-50 shadow-lg">
      <div className="flex justify-between border-b">
        <label className="">Email</label>
        <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="flex justify-between border-b">
        <label className="">First Name</label>
        <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </div>
      <div className="flex justify-between border-b">
        <label className="">Last Name</label>
        <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </div>
      <div className="flex justify-between border-b">
        <label className="">Phone</label>
        <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={phone} onChange={(e) => setPhone(e.target.value.replace(/[^0-9.]/g, ''))} />
      </div>
      <div className="flex justify-between border-b">
        <label className="">Fax</label>
        <input className="min-w-[180px] max-w-[220px] border rounded pl-1" value={fax} onChange={(e) => setFax(e.target.value.replace(/[^0-9.]/g, ''))} />
      </div>
      <div className="w-full flex justify-around mt-2">
        <button className="rounded py-1 px-4 bg-red-500 text-white" onClick={() => setEdit(false)}>Cancel</button>
        <button className="rounded py-1 px-4 bg-sunburst text-white" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  )
};

export default EditUserData;