import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../../auth/UserContext";
import HeaderMenuTab from "./HeaderMenuTab";
import { useUpdateSelectedNotif } from "../context/NotifContext";

const homeIcon = require("../../icons/homeIcon.png");
const clinCaseIcon = require("../../icons/clin-case.png");
const devicesIcon = require("../../icons/protocols.png");
const staffIcon = require("../../icons/staff.png");
const adminTabs = [
  {
    title: "Home",
    href: "/",
    icon: homeIcon,
    alt: "home icon",
  },
  {
    title: "Penpals",
    href: "/penpals",
    icon: devicesIcon,
    alt: "protocols",
  },
  {
    title: "Clin Cases",
    href: "/clinical-cases",
    icon: clinCaseIcon,
    alt: "clinical case",
  },
  {
    title: "Staff",
    href: "/staff",
    icon: staffIcon,
    alt: "staff",
  },
];

const piTabs = [
  {
    title: "Penpals",
    href: "/",
    icon: devicesIcon,
    alt: "protocols",
  },
  {
    title: "Clin Cases",
    href: "/clinical-cases",
    icon: clinCaseIcon,
    alt: "clinical case",
  },
  {
    title: "Staff",
    href: "/staff",
    icon: staffIcon,
    alt: "staff",
  },
];

const vetTabs = [
  {
    title: "Home",
    href: "/",
    icon: homeIcon,
    alt: "home icon",
  },
  {
    title: "Penpals",
    href: "/penpals",
    icon: devicesIcon,
    alt: "penpals",
  },
  {
    title: "Clinical Cases",
    href: "/clinical-cases",
    icon: clinCaseIcon,
    alt: "clinical case",
  },
  {
    title: "Staff",
    href: "/staff",
    icon: staffIcon,
    alt: "staff",
  },
];

const HeaderMenu = () => {
  const user = useUser();
  const location = useLocation();
  const [tabs, setTabs] = useState(user.SelectedRole === 'PI' ? piTabs : user.SelectedRole === 'VET' ? vetTabs : user.SelectedRole === 'ADMIN' ? adminTabs : []);
  const [activeTab, setActiveTab] = useState('');
  const updateSelectedNotif = useUpdateSelectedNotif();

  useEffect(() => {
    if (user.SelectedRole === 'ADMIN') {
      setTabs(adminTabs);
    }
    if (user.SelectedRole === 'PI') {
      setTabs(piTabs);
    }
    if (user.SelectedRole === 'VET') {
      setTabs(vetTabs);
    }
  }, [user.SelectedRole]);

  useEffect(() => {
    let currentTab;

    if (user.SelectedRole !== 'VET' && location.pathname.startsWith('/penpals')) {
      currentTab = piTabs.find(tab => tab.href === '/');
    } else {
      currentTab = tabs.find(tab => location.pathname === tab.href) ||
        tabs.find(tab => location.pathname.startsWith(tab.href) && tab.href !== '/');
    }
    if (currentTab) {
      setActiveTab(currentTab.title);
    }
  }, [location.pathname, tabs, user.SelectedRole]);

  const handleTabClick = (title) => {
    setActiveTab(title);
    updateSelectedNotif(null)
  };

  const isActiveTab = (tab) => {
    return tab.title === activeTab;
  };

  return (
    <div className="w-full">
      <div className="bg-slate-100 dark:bg-slate-800 border-slate-300 dark:border-slate-700 border-y-2">
        <div className="flex flex-row w-full px-3 overflow-auto">
          {tabs.map((tab) => (
            <Link
              key={tab.title}
              to={tab.href}
              onClick={() => handleTabClick(tab.title)}
              className={`${isActiveTab(tab) ? "bg-orange-50 dark:bg-slate-700 border border-sunburst text-sunburst" : "dark:text-white"} flex flex-grow gap-1 rounded-md items-center justify-center my-2 mx-1`}
            >
              <HeaderMenuTab tab={tab} isActive={isActiveTab(tab)} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu